import React from "react";
import { Link } from "gatsby";

export default function ProjectLink(props) {
  return (
    <p>
      <Link to={props.to}>{props.title}</Link>
      <p>
        {props.blurb || props.children}
      </p>
    </p>
  );
}