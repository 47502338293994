import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import ListItemLink from "./ListItemLink";
import { MDXProvider } from "@mdx-js/react"
import "./MasterLayout.css";

export default function MasterLayout(props) {
  const data = useStaticQuery(
    graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
  );

  return (
    <div>
      <header>
        <Link to="/">
          <h3>{data.site.siteMetadata.title}</h3>
        </Link>
        <ul className="main-menu">
          <ListItemLink to="/">Home</ListItemLink>
          <ListItemLink to="/reviews">Reviews</ListItemLink>
          {/* <ListItemLink to="/blog">Blog</ListItemLink> */}
          <ListItemLink to="/about">About</ListItemLink>
        </ul>
      </header>
      <div className="outer-tray">
        <div className="content-pane">
          <MDXProvider>{props.children}</MDXProvider>
        </div>
      </div>
    </div>
  );
}