import React from "react";
import MasterLayout from "../components/MasterLayout";
import { Link } from "gatsby";
import ProjectLink from "../components/ProjectLink";

export default function About() {
  return (
    <MasterLayout>
      <div className="main-content">
        <div>
          <p>Hi, I'm Jared.</p>
          <p>
            I'm a Software Engineer living in Beaverton, Oregon. I work for InComm InCentives in Portland. When I'm not <Link to="https://github.com/mildsauce45">writing code</Link>, <Link to="/reviews">playing video games</Link>,
            or playing board games, you can probably find me either training in taekwondo, bowling, or golfing (assuming there isn't a global pandemic raging).            
          </p>
          <p>
            I've been writing code professionally for 15 years. Currently I develop a set of dotnet core backend microservices that handle gift cards transactions, but I've spent most of my career as a front end developer, using WPF and React. I like to tinker around with different languages.
            I'm currently stanning Kotlin the most, but past languages have been Python and F#.
          </p>
        </div>
        <div>          
          <h3>Some Fun Projects</h3>
          <ProjectLink to="https://github.com/mildsauce45/spiffy" title="spiffy" blurb="A Kotlin library that works like dapper. I wrote it because I just don't like Hibernate or JPA and this let me add one or two features I like."/>
          <ProjectLink to="https://github.com/mildsauce45/UPF" title="UPF" blurb="Unity Presentation Framework. This is a WPF-like view framework for Unity. Currently only uses the old GUI-system."/>
          <ProjectLink to="https://github.com/mildsauce45/Katamari" title="Katamari">
            <span>An F# port of a fun python project by <Link to="https://github.com/SwartzCr/katamari">Noah Swartz</Link></span>
          </ProjectLink>
          <ProjectLink to="https://github.com/mildsauce45/Fulcrum" title="Fulcrum">
            <span>Another port of a pre-existing project. This one let me crib some ideas from Paul Betts' <Link to="https://github.com/reactiveui/refit">Refit</Link> library and my own <Link to="https://github.com/mildsauce45/Emitter">IL Emitter library</Link></span>
          </ProjectLink>
        </div>
        <div>
          <h3>Top 5 video games of all time</h3>
          <ol>
            <li>Final Fantasy 6 (3 US)</li>
            <li>Castlevania: Symphony of the Night</li>
            <li>Mass Effect 2</li>
            <li>Chrono Trigger</li>
            <li>Maniac Mansion 2: Day of the Tentacle</li>
          </ol>
        </div>
      </div>
    </MasterLayout>        
  );
}