import React from "react";
import { Link } from "gatsby";
import "./ListItemLink.css";

export default function ListItemLink(props) {
  return (
    <li className="header-link">
      <Link to={props.to}>{props.children}</Link>
    </li>
  );
}